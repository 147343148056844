import { getTokens } from 'utils/tokens';

interface JsonReponse {
  data: any;
  errors: { extensions: { code: string }; message: string }[];
}

export function createGraphqlFetcher<TData, TVariables>(query: string, variables?: TVariables, options?: HeadersInit) {
  return async (): Promise<TData> => {
    if (!process.env.NEXT_PUBLIC_API_URL) {
      throw new Error('Missing API Url');
    }

    const tokens = getTokens();
    const response = await fetch(process.env.NEXT_PUBLIC_API_URL, {
      method: 'POST',
      body: JSON.stringify({ query, variables }),
      headers: {
        ...options,
        'Content-type': 'application/json',
        ...(tokens?.accessToken ? { Authorization: tokens?.accessToken } : {}),
      },
    });

    const json: JsonReponse = await response.json();

    if (json?.errors?.length > 0) {
      throw new ApiError(response, 'API request went wrong', json);
    }

    return json.data;
  };
}

export class ApiError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: any;
  public readonly json: JsonReponse;

  constructor(response: Response, message: string, json: JsonReponse) {
    super(message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
    this.json = json;
  }
}
