import { captureException } from 'utils/sentry';
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { MeQuery, useLoginMutation, useMeQuery } from 'generated/graphql';
import { removeTokens, saveTokens } from 'utils/tokens';
import { useQueryClient } from '@tanstack/react-query';

interface ProviderType {
  loginUser: (emailOrUserName: string, password: string) => Promise<void>;
  logoutUser: () => Promise<void>;
  user?: MeQuery['me'];
  userLoading: boolean;
}

const initialState: ProviderType = {
  loginUser: async () => {
    //init
  },
  logoutUser: async () => {
    //init
  },
  userLoading: false,
  user: undefined,
};

const UserContext = createContext(initialState);

interface Properties {
  children: ReactNode;
}

export const UserProvider = ({ children }: Properties) => {
  const { data, isFetching, refetch } = useMeQuery(undefined, { refetchOnWindowFocus: true });
  const loginMutation = useLoginMutation();
  const queryClient = useQueryClient();

  const loginUser = useCallback(
    async (emailOrUserName: string, password: string) => {
      if (typeof window === 'undefined') {
        return;
      }

      const data = await loginMutation.mutateAsync({ emailOrUserName, password });

      if (data?.login) {
        const { accessToken, refreshToken } = data.login;
        saveTokens(accessToken, refreshToken);
        refetch();
      }
    },
    [loginMutation, refetch]
  );

  const logoutUser = useCallback(async () => {
    try {
      if (typeof window === 'undefined') {
        return;
      }

      removeTokens();
      queryClient.resetQueries();
    } catch (error) {
      captureException(error);
    }
  }, [queryClient]);

  const value = useMemo(
    () => ({ userLoading: isFetching, user: data?.me, loginUser, logoutUser }),
    [data?.me, isFetching, loginUser, logoutUser]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
