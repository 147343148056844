/* eslint-disable unicorn/prefer-module */
/* eslint-disable @typescript-eslint/no-var-requires */

import './global.css';

import { ApiError } from 'utils/createGraphqlFetcher';
import { captureException } from '@sentry/nextjs';
import { getTokens, removeTokens, saveTokens } from 'utils/tokens';
import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRefreshMutation } from 'generated/graphql';
import { UserProvider } from 'components/UserProvider';
import React, { useMemo } from 'react';
import type { AppProps } from 'next/app';

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, { trackAllPureComponents: true });
}

interface AppPropertiesWithError extends AppProps {
  err: unknown;
}

const checkIsUnauthorizedError = (error: unknown) =>
  error instanceof ApiError && error.json.errors?.some((error) => error.extensions.code === 'UNAUTHENTICATED');

// err is a workaround for https://github.com/vercel/next.js/issues/8592
const MyApp = ({ Component, pageProps, err }: AppPropertiesWithError) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: async (error, query) => {
            const isUnauthorizedError = checkIsUnauthorizedError(error);
            const tokens = getTokens();

            if (isUnauthorizedError && tokens?.accessToken && tokens?.refreshToken) {
              try {
                const response = await useRefreshMutation.fetcher({ refreshToken: tokens.refreshToken })();

                if (response.refresh.accessToken && response.refresh.refreshToken) {
                  saveTokens(response.refresh.accessToken, response.refresh.refreshToken);
                  await queryClient.refetchQueries(query.queryKey);
                } else {
                  throw new Error('Missing token or refresh token in candidate response');
                }
              } catch (_error) {
                captureException(_error);
                removeTokens();
                queryClient.resetQueries();
              }
            } else if (!isUnauthorizedError) {
              captureException(error);
            }
          },
        }),
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <UserProvider>
          <Component {...pageProps} err={err} />
        </UserProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
