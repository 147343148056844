export const AT_KEY = '15TWENTY_AT';
export const RT_KEY = '15TWENTY_RT';

export const getTokens = () => {
  if (typeof window === 'undefined') return;

  const accessToken = window.localStorage.getItem(AT_KEY);
  const refreshToken = window.localStorage.getItem(RT_KEY);

  return { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(AT_KEY, accessToken);
  window.localStorage.setItem(RT_KEY, refreshToken);

  return { accessToken: accessToken || undefined, refreshToken: refreshToken || undefined };
};

export const removeTokens = () => {
  if (typeof window === 'undefined') return;

  window.localStorage.removeItem(AT_KEY);
  window.localStorage.removeItem(RT_KEY);

  return { accessToken: undefined, refreshToken: undefined };
};
